import { useFlags } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, useEffect, useState } from 'react';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import ArrowIcon from '@/assets/images/arrow-white.svg';
import ContactClauseIcon from '@/assets/images/contract-clauses-icon.svg';
import MissingDocsIcon from '@/assets/images/file.svg';
import FinancingIcon from '@/assets/images/financing-rights-icon.svg';
import RedlineIcon from '@/assets/images/redline-icon.svg';
import DocumentIcon from '@/assets/images/sidebar-file-icon.svg';
import { ClientMatterWithDetails, DataRoomClientFile } from '@/common/types';
import LoadingSpinner from '@/components/LoadingSpinner';
import { token, trpcReact } from '@/utils/trpc';

interface ActiveTabProps {
  to: string;
  feature: boolean;
}

const ActiveTabLink = (props: PropsWithChildren<ActiveTabProps>) => {
  return (
    <NavLink
      to={props.to}
      className={({ isActive }) =>
        `${
          props.feature ? 'flex' : 'hidden'
        } gap-4 h-[40px] hover:bg-container-hover font-bold text-[14px] items-center pl-[16px] text-marveri-white ${
          isActive ? 'bg-container-hover' : ''
        }`
      }
    >
      {props.children}
    </NavLink>
  );
};

export const ClientMatter = () => {
  const [matter, setMatter] = useState<ClientMatterWithDetails | undefined>();

  const { clientNumber, matterNumber } = useParams();
  const [isQueryInProgress, setIsQueryInProgress] = useState(false);
  const [userMessage, setUserMessage] = useState<string>('');
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(false);
  const [selectedReferenceFocus, setSelectedReferenceFocus] = useState('referenced');
  const [selectedRefHighlight, setSelectedRefHighlight] = useState<number[]>([]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 1040) {
        setIsPanelCollapsed(true);
      }
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.addEventListener('resize', handleWindowResize);
    };
  }, []);

  const convertParamToInt = () => {
    if (clientNumber === undefined || matterNumber === undefined) {
      throw new Error('clientNumber and matterNumber must be defined.');
    }
    const clientNumberInt = parseInt(clientNumber);
    const matterNumberInt = parseInt(matterNumber);
    return [clientNumberInt, matterNumberInt];
  };

  const [userClientNumber, userMatterNumber] = convertParamToInt();

  trpcReact.dataRoom.get.useQuery(
    {
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      onSuccess: setMatter,
    },
  );

  interface StringDatedDataRoomClientFile extends Omit<DataRoomClientFile, 'date'> {
    date: string | null;
  }
  const convertToDate = (dataRoomFile: StringDatedDataRoomClientFile): DataRoomClientFile => {
    return {
      ...dataRoomFile,
      date: dataRoomFile.date ? new Date(dataRoomFile.date) : null,
    };
  };

  trpcReact.dataRoom.onChange.useSubscription(
    {
      // The token must be defined for the subscription to be defined, but it won't be triggered until the token is set
      token: token ?? '',
      number: userMatterNumber,
      clientNumber: userClientNumber,
    },
    {
      enabled: token !== undefined,
      onData({ data }) {
        // Convert the dates to Date objects since onData apparently converts them to strings
        const dataroomFiles = data.dataRoomFiles;
        // @ts-expect-error "type instantiation is excessively deep and possibly infinite"
        const datedDataRoomFiles: DataRoomClientFile[] = dataroomFiles.map(convertToDate);

        const updatedMatter = {
          ...data,
          dataRoomFiles: datedDataRoomFiles,
          createdAt: new Date(data.createdAt),
          updatedAt: new Date(data.updatedAt),
          folderConfig: {
            ...data.folderConfig,
            emptyFolders: data.folderConfig?.emptyFolders || [],
            hiddenFolders: data.folderConfig?.hiddenFolders || [],
            folderAliases:
              data.folderConfig?.folderAliases.map((alias) => {
                return {
                  ...alias,
                  createdAt: new Date(alias.createdAt),
                  updatedAt: new Date(alias.updatedAt),
                };
              }) || [],
          },
        };
        setMatter(updatedMatter);
      },
    },
  );

  const featureFlags = useFlags();

  const overviewOptions = [
    {
      tab: 'Documents',
      icon: DocumentIcon,
      path: 'dataroom',
      feature: true,
    },
    {
      tab: 'Missing Signatures',
      icon: MissingDocsIcon,
      path: 'missing-signatures-debug',
      feature: featureFlags.missingDocColumnSelector,
    },
    {
      tab: 'Identify Step',
      icon: MissingDocsIcon,
      path: 'missing-documents-identify',
      feature: featureFlags.missingDocColumnSelector,
    },
    {
      tab: 'Missing Documents',
      icon: MissingDocsIcon,
      path: 'missing-documents',
      feature: true,
    },
    {
      tab: 'COI',
      icon: MissingDocsIcon,
      path: 'coi-debug',
      feature: featureFlags.missingDocColumnSelector,
    },
    {
      tab: 'Veto',
      icon: MissingDocsIcon,
      path: 'veto-debug',
      feature: featureFlags.missingDocColumnSelector,
    },
    {
      tab: 'Financing Rights',
      icon: FinancingIcon,
      path: 'financing-rights',
      feature: featureFlags.vetoRightsPage,
    },
    {
      tab: 'Contract Clauses',
      icon: ContactClauseIcon,
      path: 'contract-clauses',
      feature: featureFlags.contractClausesPage,
    },
    {
      tab: 'Group Redline',
      icon: RedlineIcon,
      path: 'redline',
      feature: featureFlags.redlineFeature,
    },
  ];

  return (
    <div className="flex size-full">
      <div
        className={`${
          isPanelCollapsed ? 'w-[48px]' : 'w-[206.5px]'
        } shrink-0 bg-container-dark transition-width duration-300 ease-in-out`}
      >
        <div className="flex h-full flex-col">
          <div className="flex w-full">
            <div
              className={`${
                isPanelCollapsed ? 'm-auto' : 'ml-auto'
              } my-[6px] mr-[6px] flex h-[28px] w-[36px] cursor-pointer items-center justify-center rounded-[8px] bg-container-hover hover:bg-light-border`}
              onClick={() => setIsPanelCollapsed((currentState) => !currentState)}
            >
              <img
                src={ArrowIcon}
                className={`${isPanelCollapsed ? 'rotate-180' : ''} transition-transform`}
              />
            </div>
          </div>
          {overviewOptions.map((option) => {
            return (
              <ActiveTabLink
                feature={option.feature}
                key={option.tab}
                to={`/${userClientNumber}/${userMatterNumber}/${option.path}`}
              >
                <img
                  src={option.icon}
                  className={`${option.path === 'financing-rights' && 'size-[18px]'} size-[16px] shrink-0`}
                />
                <span className="truncate">{option.tab}</span>
              </ActiveTabLink>
            );
          })}
          <div className="flex grow"></div>
        </div>
      </div>
      <div className="flex grow bg-marveri-background">
        {matter ? (
          <Outlet
            context={{
              matter,
              isQueryInProgress,
              setIsQueryInProgress,
              userMessage,
              setUserMessage,
              setIsPanelCollapsed,
              isPanelCollapsed,
              selectedReferenceFocus,
              setSelectedReferenceFocus,
              selectedRefHighlight,
              setSelectedRefHighlight,
            }}
          />
        ) : (
          <div className="flex size-full items-center justify-center">
            <LoadingSpinner size={20} />
          </div>
        )}
      </div>
    </div>
  );
};
